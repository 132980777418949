import { IncomingHttpHeaders } from 'http';
import { fetchBrands, fetchPage, fetchRedirect } from '~/lib/api';

export type PageResolverProps = {
    headers?: IncomingHttpHeaders;
    forwardHeaders?: string[];
    url: string;
    params?: Record<string, any>;
};

export type BrandsResolverProps = {
    headers?: IncomingHttpHeaders;
    forwardHeaders?: string[];
    params?: Record<string, any>;
};

type RedirectsType = {
    data: { isPermanent: boolean; original: string; redirect: string };
    status: number;
    statusText: string;
    headers?: Headers;
};

/**
 * Helper method for looking up an array of headers
 */
const lookupHeaders = (headers: IncomingHttpHeaders, headerKeys: string[]) => {
    return headerKeys.reduce(
        (result, key) => {
            const value = headers[key];

            if (value != undefined) {
                result[key] = String(value);
            }

            return result;
        },
        {} as { [key: string]: string },
    );
};

export async function redirectsResolver(url: string): Promise<RedirectsType> {
    const response = await fetchRedirect(url);
    const { headers: responseHeaders, status, statusText } = response;

    const data = await response.json();
    return {
        headers: responseHeaders,
        status,
        statusText,
        data,
    };
}

export async function pageResolver({
    url,
    params = {},
    headers = {},
    forwardHeaders = [],
}: PageResolverProps) {
    console.time('pageResolver');
    // Forward headers to the CMS
    const requestHeaders = lookupHeaders(headers, forwardHeaders);
    const response = await fetchPage(url, params, requestHeaders);

    const { headers: responseHeaders, status, statusText, url: query } = response;

    const data = await response.json();
    console.timeEnd('pageResolver');

    return {
        headers: responseHeaders,
        status,
        statusText,
        data: {
            ...data,
            query,
        },
    };
}

export async function brandsResolver({
    params = {},
    headers = {},
    forwardHeaders = [],
}: BrandsResolverProps) {
    console.time('brandsResolver');
    // Forward headers to the CMS
    const requestHeaders = lookupHeaders(headers, forwardHeaders);
    const response = await fetchBrands(params, requestHeaders);

    const { headers: responseHeaders, status, statusText } = response;

    const data = await response.json();

    console.timeEnd('brandsResolver');

    return {
        headers: responseHeaders,
        status,
        statusText,
        data,
    };
}
