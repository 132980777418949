export const createAbsoluteUrl = (relativeUrl: string) =>
    new URL(relativeUrl, process.env.NEXT_PUBLIC_URL);

export const lowercasePathOfUrl = (relativeUrl: string) => {
    const lowercasedUrl = createAbsoluteUrl(relativeUrl);
    lowercasedUrl.pathname = lowercasedUrl.pathname.toLocaleLowerCase();

    return lowercasedUrl.toString();
};

export const hasUppercaseOnPath = (relativeUrl: string) => {
    try {
        const absoluteUrl = createAbsoluteUrl(relativeUrl);

        return /[A-Z]/.test(decodeURIComponent(absoluteUrl.pathname));
    } catch (err) {
        // In case new URL() returns an error, we will return false.
        return false;
    }
};
